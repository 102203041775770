<template>
  <div class="container">
    <div>
      <div class="u-flex">
        <img class="icon-right" src="../assets/images/icon_check_mark.png" />
        <span>{{ `${opt === "loan" ? "取款" : "还款"}申请已提交` }}</span>
      </div>

      <!-- 金额 -->
      <div class="amount u-text-center" v-if="opt === 'loan'">
        <div>
          <p class="withdrawal-amount">取款金额 (元)</p>
          <div class="withdrawal-box u-flex-col u-row-between pr">
            <b></b>
            <b></b>
            <b></b>
            <b></b>
            <p class="pa">{{ amount }}</p>
          </div>
        </div>
      </div>

      <p class="tips">
        {{
          opt === "loan"
            ? "取款金额将在1小时内打款至您绑定的银行卡中，若1小时内未收到贷款，请返回APP重新尝试取款"
            : "还款申请已提交，我们将很快处理您的还款申请，处理完成后我们将立即更新您的账单"
        }}
      </p>
      <van-button
        v-if="opt === 'loan'"
        type="primary"
        block
        round
        color="#3563FA"
        @click="handleLaterTips"
      >
        稍后通知我
      </van-button>
    </div>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TriContract",
  data() {
    return {
      client: this.MixinClientBrowser(), // 设备
      orderNo: this.$route.query.orderNo,
      opt: this.$route.query.opt, //  loan=放款、repay=还款
      amount: "",
    };
  },
  created() {
    this.setPageTitle(this.opt === "loan" ? "审批放款" : "还款");
    this.goPageParent(); // 调用客户端方法，进入此页面返回咱们APP的首页，避免返回三方上一页
    this.getOrderInfo();
  },
  methods: {
    // 稍后通知(调用客户端方法)
    handleLaterTips() {
      const { client } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("notifyPermission");
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.notifyPermission();
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },

    // 获取订单信息
    getOrderInfo() {
      const { orderNo, opt } = this;
      API_Common.queryOrderInfo(orderNo, opt).then((res) => {
        if (res.code === 200) {
          this.amount = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #111c31;

  > div {
    background: #f6f8ff;
    border-radius: 0.4rem;
    padding: 0.48rem 0.3rem 0.6rem;
  }

  .icon-right {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.1rem;
  }

  .amount {
    margin-top: 0.3rem;
    padding-top: 0.3rem;
    border-top: 0.02rem solid #e6e7ee;

    .withdrawal-amount {
      font-size: 0.26rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #868e9e;
      margin-bottom: 0.24rem;
    }

    .withdrawal-box {
      margin: 0 auto;
      width: 3.92rem;
      height: 0.9rem;

      > b {
        width: 100%;
        height: 0.02rem;
        background: #eaeaea;
      }

      .pa {
        width: 100%;
        font-size: 0.72rem;
        font-weight: 400;
        color: #111c31;
      }
    }
  }

  .tips {
    font-weight: 400;
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Regular;
    color: #868e9e;
    margin: 0.42rem 0 0.84rem;
  }
}
</style>
